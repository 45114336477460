import { t } from '@lingui/macro'
import { TimeRange } from '../types/timeRange'
import { formatHourMinute } from './timeRange'

// 🌪️ i18n warning : update accordinly in shared/src/utils/timeRange.ts 🌪️
export function translatedTimeRangeString(timeRange: TimeRange | undefined) {
  return timeRange === undefined || timeRange.start === timeRange.end
    ? t`24h/24`
    : t`de ${formatHourMinute(timeRange.start)} à ${formatHourMinute(
        timeRange.end,
      )}`
}
