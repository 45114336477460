import { Plural } from '@lingui/macro'
import {
  Connectivity as ConnectivityType,
  RoomConnectivity,
} from 'common/types'
import React from 'react'
import { DateString } from 'shared/types/utils'
import { roomDisplayName } from 'shared/utils/room'
import { formatDayOfWeek } from './utils/date'

interface Props {
  date: DateString
  connectivity: RoomConnectivity
}

export function isOffline({ connected }: ConnectivityType) {
  return connected === false
}

// count and show only ari not connected
export const Connectivity: React.FC<Props> = ({ date, connectivity }) => {
  return (
    <>
      <div className="my-4 text-lg">
        <Plural
          value={Object.values(connectivity).filter(isOffline).length}
          one={`${formatDayOfWeek(date)} : # boîtier`}
          other={`${formatDayOfWeek(date)} : # boîtiers`}
        />
        <Plural
          value={Object.values(connectivity).length}
          one={`hors ligne sur # installé`}
          other={`hors ligne sur # installés`}
        />
      </div>
      <div className="flex flex-row flex-wrap gap-4">
        {Object.entries(connectivity)
          .filter(([_, connectivity]) => isOffline(connectivity))
          .map(([room, _]) => (
            <div key={room}>
              <div className="rounded-md bg-slate-100 px-2 py-1">
                {roomDisplayName(room)}
              </div>
            </div>
          ))}
      </div>
    </>
  )
}
