import { Trans } from '@lingui/macro'
import React from 'react'
import { activities, activityData, ActivityType } from './utils/activity'

interface Props {
  selectedActivity: ActivityType | undefined
  setSelectedActivity: (activity: ActivityType) => void
}

export const ActivityLegend: React.FC<Props> = ({
  selectedActivity,
  setSelectedActivity,
}) => {
  return (
    <div>
      <div className="text-sm">
        <Trans>Cliquez sur une activité pour la sélectionner</Trans>
      </div>
      <div className="sticky top-0 z-10 mb-4 flex flex-row flex-wrap items-center justify-around gap-3 bg-white bg-opacity-90 py-3">
        {activities.map((activity) => (
          <div
            key={activity}
            onClick={() => setSelectedActivity(activity)}
            style={{
              cursor: 'pointer',
              fontWeight: selectedActivity === activity ? 'bold' : 'normal',
            }}
          >
            <span
              className={`rounded-md px-3 py-1 ${activityData[activity].color} mr-2`}
            ></span>
            {activityData[activity].title}
          </div>
        ))}
      </div>
    </div>
  )
}
