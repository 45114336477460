import { Trans } from '@lingui/macro'
import React from 'react'
import { HourMinute } from 'shared/types/timeRange'
import { Title } from './Text'

interface TimeRangeSelectorProps {
  startTime: HourMinute
  endTime: HourMinute
  setStartTime: (value: HourMinute) => void
  setEndTime: (value: HourMinute) => void
}

export const TimeRangeSelector: React.FC<TimeRangeSelectorProps> = ({
  startTime,
  endTime,
  setStartTime,
  setEndTime,
}) => {
  return (
    <div className="mb-6">
      <Title>
        <Trans>Plage horaire</Trans>
      </Title>
      <div className="flex space-x-4">
        <label className="flex items-center space-x-2">
          <Trans>Heure de début :</Trans>
          <input
            type="time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value as HourMinute)}
            className="form-input rounded-md border-gray-300 shadow-sm"
          />
        </label>
        <label className="flex items-center space-x-2">
          <Trans>Heure de fin :</Trans>
          <input
            type="time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value as HourMinute)}
            className="form-input rounded-md border-gray-300 shadow-sm"
          />
        </label>
      </div>
    </div>
  )
}
