import { DateTime } from 'luxon'
import { DateString } from 'shared/types/utils'

export function formatDayOfWeek(date: DateString) {
  // ISO 8601 YYYY-MM-DD format -> Lundi 10 avril
  return DateTime.fromISO(date).toLocaleString(
    { weekday: 'long', month: 'long', day: '2-digit' },
    { locale: 'fr-FR' },
  )
}

export function formatDate(date: DateString) {
  // ISO 8601 YYYY-MM-DD format -> DD/MM
  return `${date[8]}${date[9]}/${date[5]}${date[6]}`
}

export function getDay(date: DateString) {
  // ISO 8601 YYYY-MM-DD format -> DD
  return `${date[8]}${date[9]}`
}
